import {Injectable} from '@angular/core';
import {Config} from "../config/config";
import {TYPE} from "../types/types";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {CustomerService} from "./customer.service";
import {LocalStorageService} from "./local-storage.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private url = Config.DEFAULT_STORE_STRING === 'store' ? environment.apiUrlStore : environment.apiUrlDefault;
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(
    private http: HttpClient,
    private customerService: CustomerService,
    private localStorageService: LocalStorageService
  ) {
  }

  public setUrl(url: string): void {
    this.url = url;
  }

  public getUrl(): string {
    return this.url;
  }

  public getLanguages(): Observable<TYPE.Languages> {
    return this.http.get<TYPE.Languages>(this.url + '/' + Config.API_VERSION_STRING + '/languages', {headers: this.headers});
  }

  public getCustomer(customerKey: string): Observable<TYPE.Customer> {
    return this.http.get<TYPE.Customer>(this.url + '/' + Config.API_VERSION_STRING + '/customer/key/' + customerKey, {headers: this.headers});
  }

  public getCustomerExisting(customerKey: string): Observable<TYPE.Customer> {
    return this.http.get<TYPE.Customer>(this.url + '/' + Config.API_VERSION_STRING + '/customer/existing/' + customerKey, {headers: this.headers});
  }

  public setCustomerRegister(extension: string, first_name: string, last_name: string, email: string, user_name: string, password: string, locale: string): Observable<TYPE.Customer> {
    return this.http.post<TYPE.Customer>(
      this.url + '/' + Config.API_VERSION_STRING + '/customer/register',
      {
        extension: extension,
        first_name: first_name,
        last_name: last_name,
        email: email,
        user_name: user_name,
        password: password,
        locale: locale.toLocaleLowerCase()
      },
      {
        headers: this.headers
      });
  }

  public getUserFromToken(token: string, device_id: string | null, push_id: string | null): Observable<TYPE.User> {
    return this.http.post<TYPE.User>(
      this.url + '/' + Config.API_VERSION_STRING + '/user/token',
      {
        token: token,
        device_id: device_id,
        push_id: push_id,
        device: 'web',
        app_color: 'green' // there is no other firebase messaging service, web uses green
      },
      {
        headers: this.headers
      });
  }

  public getUserFromForm(user_name: string, password: string, device_id: string | null, push_id: string | null): Observable<TYPE.User> {
    return this.http.post<TYPE.User>(
      this.url + '/' + Config.API_VERSION_STRING + '/user/login/' + this.customerService.getCustomer()?.id,
      {
        user_name: user_name,
        password: password,
        device_id: device_id,
        push_id: push_id,
        device: 'web',
        app_color: 'green' // there is no other firebase messaging service, web uses green
      },
      {
        headers: this.headers
      });
  }

  public resetUserPassword(user_name: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.url + '/' + Config.API_VERSION_STRING + '/user/resetPassword/' + this.customerService.getCustomer()?.id,
      {
        user_name: user_name
      },
      {
        headers: this.headers
      });
  }

  public setUserRegister(first_name: string, last_name: string, email: string, user_name: string, password: string) {
    return this.http.post<boolean>(
      this.url + '/' + Config.API_VERSION_STRING + '/user/register/' + this.customerService.getCustomer()?.id,
      {
        first_name: first_name,
        last_name: last_name,
        email: email,
        user_name: user_name,
        password: password
      },
      {
        headers: this.headers
      });
  }

  public setBellNotedAll() {
    // TODO implement only for non chat notifications
    return this.http.post<boolean>(
      this.url + '/' + Config.API_VERSION_STRING + '/bell/notedAll',
      {
      },
      {
        headers: this.headers
      });
  }

  public getBell(page: number, status: 'new'|'read', module: 'calendar'|'channel'|'chat'|'intranet'|'mapp'|'meeting'|'survey'|'workflow'|null) {
    // TODO implement status and module
    //return this.http.get<TYPE.Notification[]>(this.url + '/' + Config.API_VERSION_STRING + '/bell/more/' + page, {headers: this.headers.append('Authorization', 'Bearer ' + this.localStorageService.getLocalStorage('userToken'))});
    return this.http.get<TYPE.Notification[]>('https://webapi.max-toolbox.com/' + Config.API_VERSION_STRING + '/bell/more/' + page + '?status=' + status + (module ? '&module=' + module : ''), {headers: this.headers});
  }

  public getDashboardCounter() {
    return this.http.get<TYPE.DashboardCounter>(this.url + '/' + Config.API_VERSION_STRING + '/bell/counter', {headers: this.headers});
  }

  public getProfile() {
    return this.http.get<TYPE.Profile>(this.url + '/' + Config.API_VERSION_STRING + '/user/getProfile', {headers: this.headers});
  }

  public setProfile(profile: TYPE.Profile) {
    return this.http.post(this.url + '/' + Config.API_VERSION_STRING + '/user/setProfile', profile, {headers: this.headers});
  }






  // elements
  public getGroups(page: number, searchString: string, showCounter: boolean, type: string = '') {
    return this.http.get<TYPE.GroupListItem[]>(this.url + '/' + Config.API_VERSION_STRING + '/user/groups/' + page + (searchString ? '/' + this.preFilter(searchString) : '') + (showCounter ? '?showCounter=1' : '') + (type !== '' && !showCounter ? '?type=' + type : type !== '' && showCounter ? '&type=' + type : ''), {headers: this.headers});
  }

  public getGroup(groupID: number) {
    return this.http.get<TYPE.GroupDetail>(this.url + '/' + Config.API_VERSION_STRING + '/customer/getGroup/' + groupID, {headers: this.headers});
  }

  public getUsers(page: number, searchString: string, type: 'own'|'all'|'active'|'inactive'|'registration') {
    return this.http.get<TYPE.UserListItem[]>(this.url + '/' + Config.API_VERSION_STRING + '/customer/users/' + page + '/' + type + (searchString ? '/' + this.preFilter(searchString) : ''), {headers: this.headers});
  }

  public getUser(userID: number) {
    return this.http.get<TYPE.UserDetail>(this.url + '/' + Config.API_VERSION_STRING + '/customer/getUser/' + userID, {headers: this.headers});
  }




  // helper
  private preFilter(searchString: string): string | false {
    if (searchString === undefined) {
      searchString = '';
    }
    const regex = /\//g;
    const isRegex = regex.test(searchString);
    if (isRegex) {
      searchString = searchString.replace(/\//g, '');
      if (searchString === '') {
        return false;
      }
    }
    encodeURIComponent(searchString);
    return searchString;
  }
}
