export const environment = {
  production: true,
  apiUrlDefault: 'https://webapi.max-toolbox.com',
  apiUrlStore: 'https://desktopapi.max-toolbox.com',
  firebase: {
    config: {
      "projectId": "humanstarsapp",
      "appId": "1:510685239618:web:a15824dc6d61400950d0db",
      "databaseURL": "https://humanstarsapp.firebaseio.com",
      "storageBucket": "humanstarsapp.appspot.com",
      "apiKey": "AIzaSyBlmQ8Eq0lVlpvPLbsbW0E-xR4qH49grmE",
      "authDomain": "humanstarsapp.firebaseapp.com",
      "messagingSenderId": "510685239618",
      "measurementId": "G-F0V852MNVB"
    },
    vapidKey: 'BBOHBsJkS4qBFNVY9aQGDsiNYyt30lEpehCn1Pgo9z49lYpYb4Ai7KJb2Mn4JGmVWHKB3XJHtvHxKJr55quTlwg'
  },
  modules: ['intranet', 'survey', 'workflow', 'mapp', 'chat', 'channel', 'meeting', 'calendar', 'tv', 'document']
};
